<template>
  <div>
    <img src="../assets/img/calendar.svg" class="desktop-only drawer-icon" @click="toggleDrawer">
    <img src="../assets/img/calendar.svg" class="mobile-only drawer-icon" style="cursor:pointer" @click="toggleDrawer">
    <div class="drawer-wrapper" :class="(showDrawer) ? 'showDrawer' : ''">
      <div class="drawerUnderlay" @click="showDrawer = false" />
      <div class="appointments-drawer side-drawer">
        <div class="content-wrapper">
          <div v-if="!pending">
            <img src="../assets/img/double-chevron.svg" class="sidebar-chevron" @click="toggleDrawer">
            <h1 v-if="Object.keys(processedAppointments).length > 0"> {{ getTexts('upcomingAppointments') }} </h1>
            <h1 v-else> {{ getTexts('noUpcomingAppointments') }} </h1>

            <div class="button-div">
              <button class="min-width-button light-grey-button sharp-corner-button" @click="getAppointments()"> {{ getTexts('refreshBtn') }} </button>
              <button class="min-width-button light-grey-button sharp-corner-button" @click="showDrawer = false"> {{ getTexts('closeBtn') }} </button>
            </div>

            <div v-for="(dateAppointments, date) in processedAppointments" :key="date">
              <h2> {{ date }} </h2>
              <div class="appointment-date-wrapper">
                <div
                  v-for="(appointment, count) in dateAppointments"
                  :key="`${appointment._id}_${count}`"
                  class="appointment-card"
                  :style="`border-left: 4px solid #${generateColour(appointment._id)}60;`"
                  @click="goToApp(appointment.stage, appointment._id)"
                >
                  <div class="appointment-card-header">
                    <strong> {{ formatTime(appointment.appointmentDateTime) }} </strong>
                    <br>
                    {{ appointment.lastName ? `${appointment.lastName} ${appointment.firstName}` : appointment.firstName }} - {{ appointment.personalNumber }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-else style="text-align: center; margin-top: 15px;">
            <i class="loader" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import applicationService from '../mixins/apiServices/application';
import { intToRGB, hashCode, pickTextColorBasedOnBgColorAdvanced } from '../mixins/helper';
import EventBus from '../js/eventBus';

import { useAppStore } from '../store/app';
import { useDomainStore } from '../store/domain';

export default {
  mixins: [applicationService],
  data () {
    return {
      showDrawer: false,
      pending: false,
      appointments: [],
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },
  computed: {
    appointmentsText () {
      return this.domainStore.appointmentsText();
    },
    processedAppointments () {
      const returnObj = {};
      this.appointments.forEach((a) => {
        const appointmentDate = moment(a.appointmentDateTime).format('L'); // get date key
        if (!returnObj[appointmentDate]) returnObj[appointmentDate] = []; // initialize if undefined

        returnObj[appointmentDate].push(a);
      });

      return returnObj;
    },
    currentProduct () {
      return this.appStore.currentProduct;
    },
    currentTab () {
      return this.appStore.currentTab;
    }
  },
  watch: {
    currentProduct (newValue, oldValue) {
      if (newValue !== oldValue) this.showDrawer = false;
    },
    currentTab (newValue, oldValue) {
      if (newValue !== oldValue) this.showDrawer = false;
    }
  },
  mounted () {
    EventBus.$on('toggle-drawer', this.toggleDrawerEvent);
  },
  unmounted () {
    EventBus.$off('toggle-drawer', this.toggleDrawerEvent);
  },
  methods: {
    getTexts (key) {
      if (this.appointmentsText[key]) return this.appointmentsText[key].label || key;
      return key;
    },
    async goToApp (stage, appId) {
      const closedStages = ['leadRejected', 'rejected', 'disbursed'];
      const { responseCode, data } = await this.getApplication((closedStages.includes(stage)) ? 'closed' : stage, appId);
      if (responseCode === 200) {
        this.appStore.setAppsNotLoaded();
        this.appStore.setAppStore('apps', data.applications);
        
        if (closedStages.includes(stage)) {
          this.appStore.setAppStore('closedCurrentTab', stage);
          this.appStore.setAppStore('currentTab', 'closed');
        } else {
          this.appStore.setAppStore('currentTab', stage);
        }

        this.appStore.setAppsLoaded();
        this.showDrawer = false;
      }
    },
    async toggleDrawer () {
      EventBus.$emit('toggle-drawer', { activeDrawer: 'appointments' });
      this.showDrawer = !this.showDrawer;
      if (this.showDrawer) await this.getAppointments();
    },
    async getAppointments () {
      this.pending = true;
      const { responseCode, data } = await this.retrieveAppointments();
      if (responseCode !== 200) {
        this.appointments = [];
        return;
      }
      this.appointments = (Array.isArray(data)) ? data : [];

      this.pending = false;
    },
    formatDate (val) {
      return moment(val).format('l');
    },
    formatTime (val) {
      return moment(val).format('LT');
    },
    generateColour (val) {
      return intToRGB(hashCode(val));
    },

    toggleDrawerEvent ({ activeDrawer }) {
      if (activeDrawer !== 'appointments') this.showDrawer = false;
    }
  }
}
</script>

<style scoped>
.appointments-drawer {
  height: 100%;
  width: 350px;
  position: fixed;
  z-index: -1;
  top: 0;
  right: -100%;
  background-color: #f8f8f8;
  color: #716F8B;
  overflow-x: hidden;
  transition: 0.5s;
  margin-top: 60px;
  padding-bottom: 60px;
  border: 1px solid #D8D6E8;
  border-right: none;
  box-shadow: none;
  cursor: default;
}

.button-div {
  margin: 15px 0px;
  position: relative;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.button-div > button {
  margin: 0;
}

.showDrawer > .appointments-drawer {
  right: 51px;
}

.drawerUnderlay {
  background-color: rgba(0,0,0,0.7);
  height: 100vh;
  width: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  cursor: default;
}

.showDrawer > .drawerUnderlay {
  width: 100%;
}

.appointments-drawer > .content-wrapper {
  padding: 10px 15px;
}

.content-wrapper h1 {
  font-size: 20px;
  margin-bottom: 10px;
}

.content-wrapper h2 {
  font-size: 18px;
}

.appointments-wrapper {
  padding: 0px 30px;
}

.appointment-date-wrapper {
  padding: 8px 0px;
  margin: 10px 0px 15px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 3px;
  border-left: 2px solid #D8D6E8;
}

.appointment-card {
  background-color: #FFFFFF;
  color: #353535;
  padding: 10px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 1px 1px 5px -1px #bdbdbd;
}

.appointment-card:hover {
  cursor: pointer;
  background-color: #f8f8f8;
}

.appointment-card:not(:last-child) {
  margin-bottom: 20px;
}

.drawer-icon {
  padding: 12px;
}

@media only screen and (max-width: 768px) {
  .drawerUnderlay {
    z-index: 1;
  }

  .appointments-drawer {
    margin-top: 0;
    right: -100%;
    z-index: -1;
    width: 50%;
  }

  .content-wrapper h1 {
    font-size: 18px;
  }

  .showDrawer > .appointments-drawer {
    right: 0px;
    z-index: 1;
  }

  .drawer-icon {
    padding: 0px;
  }
}
</style>
