<template>
  <div>
    <div class="searchbar-wrapper">
      <div class="search-field-wrapper">
        <img src="../assets/img/search_icon.svg">
        <input v-model="theModel" type="textbox" :placeholder="seachbarPlaceholderText" @keyup.enter="searchApp" @blur="onBlur()">
        <i v-if="pending" id="loader" class="loader" />

        <div v-if="searchDone" class="results-wrapper">
          <div class="results-header">
            <div v-if="results.length > 0"> <strong> {{ generalTexts.resultsLabel }}: </strong> </div>
            <div v-else> <strong> {{ generalTexts.noAppFound }} </strong> </div>
            <img src="../assets/img/close.svg" :title="generalTexts.clearResultTitle" @click="clearResults()">
          </div>

          <div v-if="results.length > 0">
            <div class="results">
              <div v-for="result in results" :key="result.appId" class="result-div" @click="goToApp(result)">
                <strong> {{ result.lastName ? `${result.lastName} ${result.firstName}` : result.firstName }} ({{ result[UserViewMixin_appIdentifier] }}) </strong>
                <br> <span style="text-transform: initial;"> {{ formatTime(result.activeDate) }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button class="min-width-button grey-button sharp-corner-button" @click="searchApp()">
        {{ generalTexts.searchBtnLabel }}
      </button>
    </div>

    <div v-if="error" class="error-msg"> <strong> {{ errorMsg[error] }} </strong> </div>
  </div>
</template>

<script>
import moment from 'moment';
import userViewSettingsMixin from '../mixins/userViewSettingsMixin';
import applicationServices from '../mixins/apiServices/application';

import { useAppStore } from '../store/app';
import { useDomainStore } from '../store/domain';

export default {
  mixins: [applicationServices, userViewSettingsMixin],
  data () {
    return {
      query: undefined,
      pending: false,
      searchDone: false,
      error: undefined,
      results: [],
      noResult: false,
      appStore: useAppStore(),
      domainStore: useDomainStore(),
    }
  },
  computed: {
    searchbarTexts () {
      return this.domainStore.searchbarTexts();
    },
    theModel: {
      get () {
        return this.query;
      },
      set (val) {
        this.error = undefined;
        this.query = val;
      }
    },
    generalTexts () {
      const processedText = {};
      const texts = this.searchbarTexts.general_texts;
      Object.keys(texts).forEach((i) => {
        processedText[texts[i].id] = texts[i].label
      });

      return processedText;
    },
    errorMsg () {
      const processedText = {};
      const texts = this.searchbarTexts.error_messages;
      Object.keys(texts).forEach((i) => {
        processedText[texts[i].id] = texts[i].label
      });

      return processedText;
    },
    seachbarPlaceholderText () {
      if (this.UserViewMixin_ssi) {
        return this.generalTexts.searchBarPlaceholder;
      }

      return this.generalTexts.searchBarPlaceholder_restricted;
    }
  },
  methods: {
    async goToApp (appData) {
      const { stage, appId, subStage } = appData;
      const closedStages = ['leadRejected', 'rejected', 'disbursed'];
      const { responseCode, data } = await this.getApplication((closedStages.includes(stage)) ? 'closed' : stage, appId);
      if (responseCode === 200) {
        this.appStore.setAppsNotLoaded();
        this.appStore.setAppStore('apps', data.applications);
        
        if (closedStages.includes(stage)) {
          this.appStore.setAppStore('closedCurrentTab', stage);
          this.appStore.setAppStore('currentTab', 'closed');
        } else {
          this.appStore.setAppStore('currentTab', stage);
        }

        if (subStage) {
          this.appStore.setAppStore('currentSubTab', subStage);
        }

        this.appStore.setAppsLoaded();
        this.clearResults();
      }
    },
    onBlur () {
      this.error = undefined;
    },
    clearResults () {
      this.results = [];
      this.query = undefined;
      this.searchDone = false;
    },
    async searchApp () {
      this.results = []; // reset result
      this.searchDone = false; // reset flag

      if (!this.query) {
        this.error = 'undefinedQuery';
        return;
      }

      if (String(this.query).length < 4) {
        this.error = 'tooShort';
        return;
      }

      this.pending = true;
      const searchResult = await this.searchApplication(this.query);
      if (searchResult.responseCode !== 200) this.error = 'queryFailed';
      else {
        this.results = searchResult.data;
        this.searchDone = true;
      }

      this.pending = false;
    },
    formatTime (time) {
      return moment(time).format('LLL');
    }
  }
}
</script>

<style scoped>
.searchbar-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.search-field-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.searchbar-wrapper > .search-field-wrapper > img {
  height: 16px;
  position: absolute;
  left: 10px;
}

.searchbar-wrapper #loader {
  position: absolute;
  right: 10px;
}

.searchbar-wrapper input {
  border-radius: 4px 0 0 4px;
  color: #39393F;
  padding: 5px 10px 5px 30px;
  width: 235px;
  border: 1px solid #bdbccc;
  outline: none;
  height: 30px;
  border-right: none;
}

::placeholder {
  color: #9597AE;
  font-weight: bold;
  text-transform: initial;
}

.results-wrapper {
  background-color: white;
  position: absolute;
  top: 37px;
  left: 0;
  z-index: 2;
  border: 2px solid #BDBCCC;
  border-radius: 10px;
  /* min-width: 235px; */
  min-width: 346px;
  padding: 5px;
  max-height: 300px;
  overflow: scroll;
}

.results-wrapper > .results-header {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: -5px;
  background: white;
}

.results-wrapper > .results-header > img {
  height: 18px;
}

.results-wrapper > .results-header > img:hover {
  cursor: pointer;
}

.results-wrapper .results > div {
  padding: 5px;
}

.results-wrapper .results > div:hover {
  background-color: #F8F8F8;
  cursor: pointer;
}

.results-wrapper .results > div:first-child {
  border-top: 1px solid #BDBCCC;
}

.results-wrapper .results > div:not(:last-child) {
  border-bottom: 1px solid #BDBCCC;
}

button {
  margin-top: 0px !important;
  margin-left: 10px;
}

.error-msg {
  color: #a70000;
  padding: 0px 10px;
  font-size: 0.7rem;
  text-transform: initial;
}
</style>
